<template>
  <el-dialog
    title="导入文件"
    :visible="show"
    width="60%"
    top="5vh"
    @close="close"
  >
    <div class="sg-dialog-body">
      <el-row>
        <el-col :sm="{ span: 18, offset: 3 }">
          <div>
            <div class="sg-light-gray-bg sg-pad sg-margin-bottom">
              <div class="sg-pad-tb-sm">1. 上传文件大小不能超过5M</div>
              <div class="sg-pad-tb-sm">
                2. <a :href="template" target="blank">下载模板文件</a>
              </div>
            </div>
            <el-form label-width="80px">
              <el-form-item prop="file" label="上传文件">
                <input
                  type="file"
                  class="sg-border sg-pad-sm sg-import-input"
                  @change="change($event)"
                />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ImportCard",
  props: {
    show: {
      type: Boolean,
      value: false,
    },
    url: {
      type: String,
      value: ''
    },
    template: {
      type: String,
      value: ''
    }
  },
  computed: mapState({}),
  data: () => {
    return {
      files: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      if (!this.checkFiles(this.files)) return false;
      const data = new FormData();
      data.append('file', this.files[0]);
      this.$http
        .post(this.url, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          if (res.code == 0) {
            this.$emit('close')
            this.$emit('success')
          }
        });
    },
    checkFiles(files) {
      if (files.length <= 0) {
        this.$message({
          type: "info",
          message: "上传文件不能为空",
        });
        return false;
      }
      return true;
    },
    change(e) {
      this.files = e.target.files;
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-import-input {
  width: calc(100% - 18px);
}
</style>
