<template>
  <div class="sg-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="create"
          >新建</el-button
        >
      </div>
      <div class="sg-margin-bottom">
        <el-button type="primary" size="mini" @click="switchShowImport(true)">导入</el-button>
        <el-button type="warning" size="mini" @click="saveSort">拖曳排序，点此保存</el-button>
      </div>
      <ImportCard :show="showImport" @close="switchShowImport(false)" :url="pre_uri + 'import'" :template="template_url" @success="importSuccess"></ImportCard>
      <el-tree
        :data="list"
        :indent="24"
        node-key="id"
        draggable
        default-expand-all
        :expand-on-click-node="false"
      >
        <span
          class="sg-tree-node sg-space-between sg-w100"
          slot-scope="{ data }"
        >
          <span>
            <span>{{ data.name }}</span>
          </span>
          <span>
            <el-button
              :type="data.show == 2 ? 'info' : 'success'"
              size="mini"
              @click="editShow(data)"
              >{{ data.show == 2 ? "隐藏" : "显示" }}</el-button
            >
            <el-button type="primary" size="mini" @click="edit(data)"
              >编辑</el-button
            >
            <el-button type="danger" size="mini" @click="deleteRow(data)"
              >删除</el-button
            >
          </span>
        </span>
      </el-tree>
    </el-card>
    <el-dialog
      :title="action == 'create' ? '新建' : '编辑'"
      :visible.sync="showDialog"
      width="60%"
      top="5vh"
    >
      <div class="sg-dialog-body">
        <el-row>
          <el-col :sm="{ span: 18, offset: 3 }">
            <el-form
              ref="createEditForm"
              :model="item"
              label-width="80px"
              :rules="rules"
            >
              <el-form-item prop="name" label="分类名称">
                <el-input
                  v-model="item.name"
                  placeholder="请输入分类名称"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="show" label="显示/隐藏">
                <el-radio-group v-model="item.show">
                  <el-radio :label="1">显示</el-radio>
                  <el-radio :label="2">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">提交</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import { mapState } from "vuex"
import ImportCard from '@/components/Admin/ImportCard.vue'
import { TEMPLATE_URL } from '@/services/env.js'

export default {
  name: "Category",
  components: {
    ImportCard
  },
  data() {
    return {
      title: "分类列表",
      template_url: TEMPLATE_URL + '目录模板.xlsx',
      list: [],
      total: 0,
      pageSize: 15,
      current: 1,
      showDialog: false,
      showImport: false,
      pre_uri: "categories/",
      // create/update
      action: "create",
      emptyItem: {
        name: "",
        show: 1,
      },
      item: {},
      searchItem: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
      },
    };
  },
  computed: {
    totalPages: () => {
      if (this.total) return Math.ceil(this.total / this.pageSize);
      else return 1;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    importSuccess() {
      this.getList()
    },
    switchShowImport(show) {
      this.showImport = show;
    },
    saveSort() {
      this.$http
        .post(this.pre_uri + "saveSort", {
          list: this.list,
        })
        .then((res) => {
          if (res.code == 0) this.getList();
        });
    },
    search() {
      this.current = 1;
      this.getList();
    },
    getList() {
      var that = this;
      this.$store.commit("updateLoading", true);
      this.$http
        .post(
          this.pre_uri + "get",
          Object.assign(
            {
              page: this.current,
            },
            this.searchItem
          )
        )
        .then((res) => {
          this.$store.commit("updateLoading", false);
          if (res.code == 0) {
            that.list = res.data;
          }
        });
    },
    pageChange(current) {
      this.current = current;
      this.getList();
    },
    create() {
      this.item = Object.assign({}, this.emptyItem);
      this.action = "create";
      this.switchShowDialog(true);
    },
    editShow(item) {
      this.item = Object.assign({}, item, {
        show: item.show == 2 ? 1 : 2,
      });
      this.action = "edit";
      this.submit();
    },
    edit(item) {
      this.item = Object.assign({}, item);
      this.action = "edit";
      this.switchShowDialog(true);
    },
    deleteRow(row) {
      this.$confirm("确定删除该分类及其所有子分类吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(this.pre_uri + "delete", {
              id: row.id,
            })
            .then((res) => {
              if (res.code == 0) {
                this.getList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    switchShowDialog(show) {
      this.showDialog = show;
    },
    submit() {
      var url = this.pre_uri + (this.action == "create" ? "store" : "update");
      this.$http.post(url, this.item).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.switchShowDialog(false);
          this.getList();
        }
      });
    },
  },
};
</script>
